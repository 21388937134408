<script>
  import { onMount, afterUpdate } from 'svelte'
  import { currentFrame, selectedProject, sliderValue } from './stores.js'

  import { backendUrl } from './v02/stores/urls'
  import { writable } from 'svelte/store'
  import { selectedLayers } from './v02/stores/timeline'

  import { activeProject } from './v02/stores/project'

  let firstFrameSrc = writable('')
  let secondFrameSrc = writable('')
  let imageContainer

  let dimensions = { width: 0, height: 0 }
  let maxSize = 0

  const getDimensions = () => {
    if (imageContainer) {
      const rect = imageContainer.getBoundingClientRect()
      dimensions = {
        width: rect.width,
        height: rect.height,
      }
      maxSize = Math.floor(Math.max(rect.width, rect.height))
      console.log('maxSize', maxSize)
    } else {
      maxSize = Math.floor(
        Math.max(window.innerHeight, window.innerWidth * 0.6)
      ) // timeline is 40hw so the rest is 60% hw
    }
  }

  function setFrameSrc(selectedLayers, currentFrame) {
    $firstFrameSrc = ''
    $secondFrameSrc = ''
    console.log('selectedLayers.length', selectedLayers.length)
    console.log('firstFrameSrc', $firstFrameSrc)
    if (selectedLayers.length == 1) {
      if (selectedLayers[0].clips.length > 0) {
        $firstFrameSrc = `${backendUrl}/preview/layer/${selectedLayers[0].id}/${currentFrame}/${maxSize}?v=${selectedLayers[0]?.clips[0]?.asset?.progress || 0}`
      }
    }
    if (selectedLayers.length == 2) {
      if (selectedLayers[0].clips.length > 0) {
        $firstFrameSrc = `${backendUrl}/preview/layer/${selectedLayers[0].id}/${currentFrame}/${maxSize}?v=${selectedLayers[0]?.clips[0]?.asset?.progress || 0}`
      }
      if (selectedLayers[1].clips.length > 0) {
        $secondFrameSrc = `${backendUrl}/preview/layer/${selectedLayers[1].id}/${currentFrame}/${maxSize}?v=${selectedLayers[1]?.clips[0]?.asset?.progress || 0}`
      }
    }
  }

  let lastExecution = 0
  let container
  let image1
  let image2
  let left
  let right
  let width
  let height

  function handleDrag(event) {
    const now = Date.now()
    if (now - lastExecution < 10) return // throttle every 50ms
    lastExecution = now

    let newValue = ((event.clientX - left) / width) * 100

    newValue = Math.max(0, Math.min(100, newValue)) // Ensure value stays between 0 and 100
    $sliderValue = newValue
  }

  function startDrag(event) {
    event.preventDefault()
    document.addEventListener('mousemove', handleDrag)
    document.addEventListener('mouseup', stopDrag)
  }

  function stopDrag() {
    document.removeEventListener('mousemove', handleDrag)
    document.removeEventListener('mouseup', stopDrag)
  }

  function updateContainerSize() {
    if (image1) {
      const rect = image1.getBoundingClientRect()
      if (rect.width > 0 && rect.height > 0) {
        left = rect.left
        right = rect.right
        width = rect.width
        height = rect.height
        container.style.width = `${rect.width}px`
        container.style.height = `${rect.height}px`
        container.style.top = rect.top
        container.style.left = rect.left
      }
    }
  }

  onMount(() => {
    getDimensions()
    window.addEventListener('resize', getDimensions)

    setFrameSrc($selectedLayers, $currentFrame)
    updateContainerSize()

    window.addEventListener('resize', () => {
      updateContainerSize()
    })
    document.addEventListener('mouseup', stopDrag)

    return () => {
      window.removeEventListener('resize', getDimensions)
    }
  })

  afterUpdate(() => {
    getDimensions()
    updateContainerSize()
  })

  $: $activeProject, setFrameSrc($selectedLayers, $currentFrame)
</script>

<div>
  <div>
    <div class="vert-container">
      {#if $selectedLayers.length > 0}
        <div
          class="layer-name"
          style="width: 50vw; display: flex; justify-content: space-between; align-items: center; align-self: center;"
        >
          <div>{$selectedLayers[0]?.name || ''}</div>
          <div>{$selectedLayers[1]?.name || ''}</div>
        </div>

        <div class="image-container" bind:this={imageContainer}>
          <!-- First video player -->
          {#if $firstFrameSrc != ''}
            <img bind:this={image1} src={$firstFrameSrc} alt="Video preview" />
          {/if}
          <!-- Second video player -->
          {#if $secondFrameSrc != ''}
            <!-- <div class="layer-name"  style='right: 50px;'>
            {$selectedLayers[1].name}
          </div> -->
            <img
              bind:this={image2}
              src={$secondFrameSrc}
              alt="Render Preview"
              style="--clip-percent: {$sliderValue}%;"
            />
          {/if}
          <div
            class="img"
            bind:this={container}
            style="position: absolute; z-index:5;"
          >
            {#if $selectedLayers.length == 2}
              <div
                class="slider-handle"
                style="left: calc({$sliderValue}%); height: {height}px;"
                on:mousedown={startDrag}
              >
                <div class="polygon-shape"></div>
              </div>
            {/if}
          </div>
        </div>
      {:else if !$selectedProject}
        <div class="image-container">
          <div class="img">Please select a project.</div>
        </div>
      {:else if $selectedLayers.length == 0}
        <div class="image-container">
          <div class="img">Please select a layer to preview.</div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .slider-handle {
    position: relative;
    top: 0;
    bottom: 0;
    width: 2px;
    background: var(--text-main);
    cursor: grab;
    z-index: 5;
    transform: translateX(-50%);
    height: 100%;
  }

  .slider-handle:active {
    cursor: grabbing;
  }

  .polygon-shape {
    position: absolute;
    display: block;
    background-color: var(--text-main);
    top: 50%;
    left: -8px;
    height: 40px;
    width: 18px;
    aspect-ratio: cos(30deg);
    clip-path: polygon(-50% 50%, 50% 100%, 150% 50%, 50% 0);
  }

  .polygon-shape:before,
  .polygon-shape:after {
    content: '';
    width: 1px;
    height: 13px;
    position: absolute;
    background: var(--text-main-invert);
    border-radius: 1px;
    top: 13px;
  }

  .polygon-shape:before {
    left: 6px;
  }

  .polygon-shape:after {
    left: 11px;
  }

  .image-container {
    height: calc(100vh - 70px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .image-container img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
  }

  .image-container img:first-child {
    z-index: 1;
  }

  .image-container img:nth-child(2) {
    z-index: 2;
    clip-path: inset(0 0 0 var(--clip-percent));
  }

  .vert-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
  }

  .layer-name {
    position: absolute;
    bottom: 10px;

    background-color: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(5px);
    z-index: 5;
    margin: 3px;
    padding: 5px;
    border-radius: 8px;
    font-size: 12px;
  }
</style>
