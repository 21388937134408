<script>
  import Inputs from './SettingsUI/Inputs.svelte'
  import { appSettings } from './v02/stores/appSettings'
  import {
    settings,
    selectedProject,
    currentFrame,
    range_min,
    range_max,
  } from './stores'
  import { addLayerOnClone } from './v02/controllers/layerController'
  import Tab from './SettingsUI/Tab.svelte'
  import TabContainer from './SettingsUI/TabContainer.svelte'
  import { stringifyOnLoad, prepareSettings } from './SettingsUI/utils'
  import {
    activeLayer,
    timelineMaxFrames,
    selectedLayers,
  } from './v02/stores/timeline'
  import { onMount } from 'svelte'

  import { renderLayer } from './v02/services/renderService'
  import { activeTimeline } from './v02/stores/timeline'
  import {
    updateLayer,
    addLayer,
    cloneLayer,
  } from './v02/services/layerService'
  import { notificationMessage } from './stores/notification'
  let oldSettings = JSON.stringify($settings)
  let settingsBkup = { ...$settings }
  import { validateSettings } from './controllers/settingsController'
  import {
    selectLayer,
    addLayerOnVideo,
  } from './v02/controllers/layerController'
  import { getRenderEngine } from './controllers/renderEngineController'
  import { writable } from 'svelte/store'

  import {
    renderSettingsTabs,
    renderSettingsLabels,
    renderSettingsTypes,
  } from './data/renderEngine'

  import {
    getInitSettings,
    getSettings,
    setSettings,
    prepareSettingsForSaving,
  } from './controllers/settingsController'

  async function handleNewRenderLayer() {
    await selectLayer($activeLayer)
    addLayerOnVideo($activeLayer)
  }

  function handleFocus() {
    oldSettings = JSON.stringify($settings)
    settingsBkup = { ...$settings }
  }

  export async function saveSettings(event) {
    if (JSON.stringify($settings) == oldSettings) {

      return
    }
    let clean_settings = $settings
    clean_settings = prepareSettingsForSaving(clean_settings)

    $activeLayer.data['render_settings'] = clean_settings
    updateLayer($activeLayer)

    oldSettings = JSON.stringify($settings)
    settingsBkup = $settings
  }

  async function handleRenderLayer(parentLayer, range) {
    let frameRange = range

    if (!validateSettings(settings, appSettings, notificationMessage)) {
      return
    }

    $notificationMessage = {
      title: `Queued frames ${frameRange[0]}-${frameRange[1]} from Layer ${parentLayer?.name}.`,
      text: 'Clip render has been queued. Check render queue for additional info.',
    }

    if ($activeLayer.type == 'RENDER') {
      // make new clip
      $activeLayer
      let result = await renderLayer(parentLayer, frameRange, $settings)
      if (result?.layers) {
        //we got a timeline
        let numLayers = $activeTimeline?.layers?.length || 0
        $activeTimeline = result 
        
        $activeLayer = $activeTimeline?.layers[numLayers-1]
      } else {
        $activeLayer = result
        $activeTimeline.layers = $activeTimeline.layers.map((c) =>
          c.id === $activeLayer.id ? $activeLayer : c
        )
      }
    } else {
      alert('Not expected to get here :D')
      // if not a render - then add a render layer for video
      // $activeTimeline = await renderLayer(
      //   parentLayer,
      //   null,
      //   $activeTimeline,
      //   filepath,
      //   frameRange,
      //   $settings,
      //   {
      //     video:
      //       parentLayer?.data?.sources?.video || parentLayer?.clips[0].asset,
      //   },
      //   $settings.renderEngine
      // );
    }
  }

  async function render(mode) {
    let range = [0, 0]
    if (mode == 'single') {
      range = [parseInt($currentFrame, 10), parseInt($currentFrame, 10) + 1]
    } else {
      range = [
        parseInt(($range_min * $timelineMaxFrames) / 100, 10),
        parseInt(($range_max * $timelineMaxFrames) / 100, 10),
      ]
    }
    handleRenderLayer($activeLayer, range)
  }

  function readFileAsText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = () => reject(reader.error)
      reader.readAsText(file)
    })
  }

  async function uploadSettings(event) {
    const files = event.target.files
    if (files.length === 0) {
      console.log('No file selected!')
      return
    }
    const fname = files[0].name
    console.log('update preview', fname)
    let loaded_settings = await readFileAsText(files[0])
    try {
      loaded_settings = await JSON.parse(loaded_settings)
    } catch (error) {
      $notificationMessage = {
        title: `Error loading settings from ${fname}`,
        text: error,
        type: 'error',
        duration: 10000,
      }
    }

    loaded_settings['controlnet_multimodel'] = await JSON.parse(
      loaded_settings['controlnet_multimodel']
    )
    for (const [key, value] of Object.entries(
      loaded_settings['controlnet_multimodel']
    )) {
      if (
        (value.weight > 0) &
        !loaded_settings['controlnet_multimodel'][key].enabled
      ) {
        loaded_settings['controlnet_multimodel'][key]['enabled'] = true
      }
    }

    loaded_settings = await stringifyOnLoad(prepareSettings(loaded_settings))
    let temp_settings = $settings.renderSettings[$settings.renderEngine]
    for (const key of Object.keys(loaded_settings)) {
      temp_settings[key] = loaded_settings[key]
    }

    console.log('loaded_settings0', loaded_settings, temp_settings)

    $settings.renderSettings[$settings.renderEngine] = temp_settings
    await saveSettings(selectedProject, settings)
    $notificationMessage = {
      title: `Settings loaded!`,
      text: `Successfully loaded settings from ${fname}`,
    }
  }

  // $: $settings, debouncedSaveSettings();

  const tabs = writable([])
  let selectedRenderEngineDropdown = 'MagoV1'
  // let remountTabs = false;
  async function handleRenderEngineChange() {
    // await tabs.set([])
    // remountTabs = !remountTabs;
    $settings.renderEngine = getRenderEngine(selectedRenderEngineDropdown)
    $tabs = renderSettingsTabs[$settings.renderEngine]
    console.log(
      '$settings.renderEngine, selectedRenderEngineDropdown',
      $settings.renderEngine,
      selectedRenderEngineDropdown
    )
    console.log(renderSettingsTabs[$settings.renderEngine])
    console.log('tabs', $tabs)
    // saveSettings()
  }

  function isDisabled(activeLayer) {
    if (activeLayer?.clips.length > 0) {
      return true
    }
    return false
  }
  let disabled = false
  $: disabled = isDisabled($activeLayer)

  onMount(() => {
    setSettings($activeLayer?.data?.render_settings || getInitSettings()) //initialize settings
    console.log('init settings', getSettings())
    $tabs = renderSettingsTabs[$settings.renderEngine]
    console.log('------------tabs', $tabs)
  })

  // $: $settings, handleRenderEngineChange()
</script>

<div class="sidebar">
  {#if $selectedLayers[0]?.type === 'RENDER'}
    <div class="settings-container">
      <TabContainer activeTabs={renderSettingsTabs[$settings.renderEngine]}>
        {#each [...renderSettingsTabs['magov1'], ...renderSettingsTabs['comfyui']] as tab}
          <Tab label={tab.label}>
            {#if tab.label.split('_')[0] == 'main'}
              <Inputs
                input_type={{
                  type: 'dropdown',
                  values: [
                    { name: 'magov1', label: 'MagoV1' },
                    { name: 'comfyui', label: 'ComfyUI' },
                  ],
                }}
                label="Render Engine"
                bind:value={$settings.renderEngine}
                {saveSettings}
                {handleFocus}
                {disabled}
              />
              <Inputs
                input_type={{
                  type: 'dropdown',
                  values: $activeTimeline?.layers
                    .filter((c) => c.type !== 'RENDER')
                    .map((layer) => ({
                      name: layer.id,
                      label: layer.name,
                    })),
                }}
                label="Video Source"
                bind:value={$settings.video_init_path}
                {saveSettings}
                {handleFocus}
                {disabled}
              />
              {#if $appSettings?.filenames['checkpoint_dir']?.length > 0}
                <Inputs
                  input_type={{
                    type: 'dropdown',
                    values: $appSettings?.filenames['checkpoint_dir'].map(
                      (file) => ({
                        name: file,
                        label: file,
                      })
                    ),
                  }}
                  label="Model checkpoint"
                  bind:value={$settings.renderSettings[$settings?.renderEngine]
                    .model_path}
                  {saveSettings}
                  {handleFocus}
                  {disabled}
                />
              {:else}
                <Inputs
                  input_type="text"
                  label="Model checkpoint"
                  value={'Please specify model folder in Mago settings'}
                  {saveSettings}
                  {handleFocus}
                  disabled={true}
                />
              {/if}
              {#if $appSettings?.filenames['vae_dir']?.length > 0}
                <Inputs
                  input_type={{
                    type: 'dropdown',
                    values: [
                      ...$appSettings?.filenames['vae_dir'].map((file) => ({
                        name: file,
                        label: file,
                      })),
                      { name: 'None', label: 'None' },
                    ],
                  }}
                  label="VAE checkpoint"
                  bind:value={$settings.renderSettings[$settings?.renderEngine]
                    .vae_path}
                  {saveSettings}
                  {handleFocus}
                  {disabled}
                />
              {:else}
                <Inputs
                  input_type="text"
                  label="VAE checkpoint"
                  value={'Please specify VAE folder in Mago settings'}
                  {saveSettings}
                  {handleFocus}
                  disabled={true}
                />
              {/if}
              {#if $settings.renderSettings[$settings.renderEngine]?.animatediff_model_path !== undefined}
                {#if $appSettings?.filenames['animatediff_dir']?.length > 0}
                  <Inputs
                    input_type={{
                      type: 'dropdown',
                      values: $appSettings?.filenames['animatediff_dir'].map(
                        (file) => ({
                          name: file,
                          label: file,
                        })
                      ),
                    }}
                    label="Animatediff checkpoint"
                    bind:value={$settings.renderSettings[
                      $settings?.renderEngine
                    ].animatediff_model_path}
                    {saveSettings}
                    {handleFocus}
                    {disabled}
                  />
                {:else}
                  <Inputs
                    input_type="text"
                    label="Animatediff checkpoint"
                    value={'Please specify Animatediff folder in Mago settings'}
                    {saveSettings}
                    {handleFocus}
                    disabled={true}
                  />
                {/if}
              {/if}
            {/if}
            {#if tab.label == 'misc'}
              <div>
                <label
                  class="choose-file"
                  style="width: 100px; margin-bottom: 15px;"
                >
                  Load settings
                  <input
                    type="file"
                    class="hidden"
                    id="settings1"
                    accept="text/plain"
                    on:change={uploadSettings}
                    {disabled}
                  />
                </label>
              </div>
            {/if}
            {#if tab.settings && $settings.renderEngine}
              {#each tab.settings as key}
                {#if key != 'video_init_path'}
                  <div
                    class="setting {key === 'video_init_path'
                      ? 'video-path'
                      : ''}"
                  >
                    <Inputs
                      input_type={renderSettingsTypes[$settings.renderEngine][
                        key
                      ]}
                      label={renderSettingsLabels[$settings.renderEngine][key]}
                      bind:value={$settings.renderSettings[
                        $settings.renderEngine
                      ][key]}
                      {saveSettings}
                      {handleFocus}
                      {disabled}
                    />
                  </div>
                {/if}
              {/each}
            {/if}
          </Tab>
        {/each}
      </TabContainer>
    </div>

    <div class="horiz-container button-wrapper">
      {#if !disabled}
        <button
          on:click={() => render('single')}
          style="width: 210px; "
          class="mago-button">Render frame #{$currentFrame}</button
        >
        <button
          on:click={() => render('range')}
          style="width: 210px; "
          class="mago-button"
          >Render range {parseInt(($range_min * $timelineMaxFrames) / 100, 10)}—{parseInt(($range_max * $timelineMaxFrames) / 100, 10)}</button
        >
      {:else}
        <button
          on:click={() => addLayerOnClone($activeLayer)}
          style="width: 210px; "
          class="mago-button">Clone and edit layer</button
        >
        <div>The settings are locked because the layer has a render.</div>
      {/if}
    </div>
  {:else}
    <div class="settings-container">
      <TabContainer>
        <Inputs
          input_type="textarea"
          label="Original Filename"
          value={$selectedLayers[0]?.clips[0]?.asset?.name}
          disabled={true}
        />
        <Tab label="Main">
          <Inputs
            input_type="textarea"
            label="Video Source"
            value={$selectedLayers[0]?.clips[0]?.asset?.filepath}
            disabled={true}
          />
          <Inputs
            input_type="text"
            label="Total frames"
            value={$selectedLayers[0]?.clips[0]?.asset?.data.duration}
            disabled={true}
          />
          <Inputs
            input_type="text"
            label="Size (HxW)"
            value={$selectedLayers[0]?.clips[0]?.asset?.data.size.join('x')}
            disabled={true}
          />
        </Tab>
      </TabContainer>
    </div>
    <div class="horiz-container">
      <div style="height: 130px; margin-top: 15px;">
        <div class="setting">
          <button
            on:click={handleNewRenderLayer}
            style="width: 210px; "
            class="mago-button">Create new render from this video</button
          >
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .video-path {
    display: grid;
    grid-template-columns: 2fr 0.5fr;
    align-items: center;
    gap: 16px;
  }
  .choose-file {
    border: 1px solid #96959c;
    border-radius: 4px;
    color: #96959c;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 4px 10px;
    height: 26px;
  }

  .choose-file:hover {
    border: 1px solid #f8f8f1;
    color: #f8f8f1;
  }

  .choose-file:active,
  .choose-file:not(:disabled):active {
    border: 1px solid var(--text-action200);
    color: var(--text-action200);
    background-color: transparent;
  }

  .horiz-container {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space items evenly between the main axis */
    flex-direction: row;
    justify-content: center;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    color: var(--text-secondary);
  }

  .settings-container {
    display: flex;
    justify-content: space-around;
    width: 620px;
    flex-direction: column;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .settings-container:first-child {
    flex-grow: 1;
    max-height: calc(
      90vh - 100px
    ); /* Adjust based on the height of the second container */
  }

  .hidden {
    display: none; /* Hide the actual file input */
  }

  .button-wrapper {
    gap: 15px;
    margin-top: 15px;
  }
</style>
