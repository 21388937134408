<script>
  import { onMount } from 'svelte'
  import axios from 'axios'
  import { getToken } from '../services/authService'
  import { backendUrl } from '../stores/urls'
  import { appSettings } from '../stores/appSettings'
  import Modal from './Modal.svelte'
  import Gear from '../../icons/Gear.svelte'

  const API_URL = `${backendUrl}/settings`

  let isOpen = false
  export let buttonStyle =
    'z-index: 100; margin-top: 5px; border: none; margin-bottom: 0;'

  const fetchSettings = async () => {
    const token = getToken()
    if (!token) {
      console.error('No token found')
      return
    }

    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      appSettings.set(response.data.data || {})
    } catch (error) {
      console.error('Error fetching settings:', error)
    }
  }

  const openModal = async () => {
    await fetchSettings()
    isOpen = true
  }

  const close = (save = true) => {
    if (save) {
      saveSettings()
    }
    isOpen = false
  }

  const saveSettings = async () => {
    const token = getToken()
    if (!token) {
      console.error('No token found')
      return
    }

    try {
      const response = await axios.put(
        API_URL,
        { data: $appSettings },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      appSettings.set(response.data.data || {})
    } catch (error) {
      console.error('Error saving settings:', error)
    }
  }

  onMount(fetchSettings)

  let possibleSettings = [
    {
      label: 'Controlnet Directory',
      name: 'controlnet_dir',
      assetFoler: true,
    },
    {
      label: 'Lora Directory',
      name: 'lora_dir',
      assetFoler: true,
    },
    {
      label: 'Embeddings Directory',
      name: 'embeddings_dir',
      assetFoler: true,
    },
    {
      label: 'Animatediff Directory',
      name: 'animatediff_dir',
      assetFoler: true,
    },
    {
      label: 'Checkpoint Directory',
      name: 'checkpoint_dir',
      assetFoler: true,
    },
    {
      label: 'VAE Directory',
      name: 'vae_dir',
      assetFoler: true,
    },
    // {
    //   label: 'MagoV1 Directory',
    //   name: 'magov1_dir',
    //   assetFoler: false,
    // },
    // {
    //   label: 'Comfyui Directory',
    //   name: 'comfyui_dir',
    //   assetFoler: false,
    // },
  ]
</script>

<main>
  <Modal
    onOpen={async () => {
      await fetchSettings()
    }}
    onClose={() => close(true)}
    {buttonStyle}
  >
    <span slot="button" class="settings-trigger">
      <Gear />
    </span>
    <div slot="main">
      <div class="settings-container">
        <h2>Settings</h2>
        {#if $appSettings?.filenames && $appSettings?.folder_paths}
          {#each possibleSettings as setting}
            <div class="setting">
              <label
                >{setting.label}
                {#if setting.name in $appSettings?.filenames}
                  ({$appSettings?.filenames[setting.name].length})
                {/if}
                <input
                  type="text"
                  bind:value={$appSettings.folder_paths[setting.name]}
                  class="setting-input form-control"
                /></label
              >
            </div>
          {/each}
        {/if}
      </div>
    </div>
    <div slot="closeButton">Save</div>
  </Modal>
</main>

<style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: 500;
  }

  .modal.open {
    display: block;
    z-index: 500;
  }

  .modal-content {
    position: relative;
    margin: auto;
    padding: 20px;
    width: 80%;
    background-color: white;
    z-index: 500;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .settings-container {
    display: flex;
    justify-content: space-around;
    width: 100%; /* Fixed width for the settings column */
    flex-direction: column;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Align settings to the right */
  .settings {
    text-align: right;
    width: 100%;
  }

  .form-control {
    margin-top: 5px;
    margin-bottom: 15px; /* Spacing between form elements */
    width: calc(100% - 50px); /* Full width to ensure alignment */
  }

  .setting {
    width: 100%;
  }

  .settings-trigger:hover {
    & > svg > path {
      transition: 200ms;
      fill: var(--text-hover);
    }
  }
</style>
