import {
  settingTypes as magov1SettingTypes,
  settingLabels as magov1SettingLabels,
  settings_tabs as magov1SettingsTabs,
} from '../data/magov1/data'
import {
  settingTypes as comfySettingTypes,
  settingLabels as comfySettingLabels,
  settingsTabs as comfySettingsTabs,
} from '../data/comfyui/data'

export const renderEngineDict = {
  MagoV1: 'magov1',
  ComfyUI: 'comfyui',
}

export const renderSettingsLabels = {
  magov1: magov1SettingLabels,
  comfyui: comfySettingLabels,
}

export const renderSettingsTypes = {
  magov1: magov1SettingTypes,
  comfyui: comfySettingTypes,
}

export const renderSettingsTabs = {
  magov1: magov1SettingsTabs,
  comfyui: comfySettingsTabs,
}
