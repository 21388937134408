export const settingLabels = {
  positive_prompt: 'Positive Prompt:',
  negative_prompt: 'Negative Prompt:',
  seed: 'Seed:',
  video_init_path: 'Video Path:',
  model_path: 'Checkpoint Path:',
  vae_path: 'VAE Path:',
  controlnet_model_path: 'ControlNet Model Path:',
  animatediff_model_path: 'AnimateDiff Model Path:',
}

export const settingTypes = {
  positive_prompt: 'text',
  negative_prompt: 'text',
  seed: 'int',
  video_init_path: 'hidden',
  model_path: 'hidden',
  vae_path: 'hidden',
  controlnet_model_path: 'text',
  animatediff_model_path: 'hidden',
  output_dir: 'hidden',
}

export const defaultSettings = {
  positive_prompt: 'A cat',
  negative_prompt: 'bad quality, blur, watermark',
  seed: 123,
  video_init_path: '',
  model_path: '',
  vae_path: '',
  controlnet_model_path: '',
  animatediff_model_path: '',
  output_dir: '',
}

const mainLabels = [
  'video_init_path',
  'model_path',
  'vae_path',
  'controlnet_model_path',
  'animatediff_model_path',
  'positive_prompt',
  'negative_prompt',
  'seed',
]

export const settingsTabs = [
  {
    label: 'main_comfy',
    settings: mainLabels,
  },
]
