<script>
  import { onMount, onDestroy } from 'svelte'
  import axios from 'axios'
  import { backendUrl } from './v02/stores/urls'
  import { activeProject } from './v02/stores/project'
  import Timeline from './v02/components/Timeline.svelte'
  import { activeLayer } from './v02/stores/timeline'
  import ZoomIn from './icons/ZoomIn.svelte'
  import ZoomOut from './icons/ZoomOut.svelte'
  import {
    timelines,
    activeTimeline,
    timelineWidthPx,
    timelineMaxFrames,
    timelineScaledWidthPx,
    timelineScale,
    timelineScrollOffset,
    controlsWidth,
  } from './v02/stores/timeline'
  import RangeSlider from './v02/components/RangeSlider.svelte'
  import Ruler from './Ruler.svelte'
  import { currentFrame, range_min, range_max } from './stores'
  const TIMELINE_MINFRAMES = 50
  let timelineContainerWidth = 500

  function getContainerWidth() {
    const rect = container.getBoundingClientRect()
    return rect.width - 20
  }

  const fetchTimelinesMany = async (activeProject) => {
    if (!activeProject) {
      return
    }
    let projectId = activeProject.id
    try {
      console.log('activeProject', projectId)
      const response = await axios.get(`${backendUrl}/timelines/${projectId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      console.log('response', response.data)
      timelines.set(response.data)
      console.log('timelines', $timelines)
      if (response.data.length > 0) {
        activeTimeline.set(response.data[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  let container

  function scaleTimeline(timelineWidthPx, timelineScale) {
    if (container) {
      timelineContainerWidth = getContainerWidth()
    }
    let oldWidth = $timelineScaledWidthPx
    $timelineScaledWidthPx = timelineWidthPx / (timelineScale / 100)
    $timelineScaledWidthPx = Math.min(
      (timelineContainerWidth / TIMELINE_MINFRAMES) * $timelineMaxFrames,
      $timelineScaledWidthPx
    )
    console.log('timelineScaledWidthPx', $timelineScaledWidthPx)
  }

  function scaleX(x) {
    x = (x / $timelineWidthPx) * $timelineScaledWidthPx
    return x
  }

  let visiblePercentage = 0
  let visiblePixels = 0
  let offsetLeft = 0
  let totalWidth = 0
  let clientWidth = 0

  const calculateVisiblePercentage = () => {
    if (container) {
      let { scrollLeft, scrollWidth, clientWidth } = container
      const visibleWidth = Math.min(scrollWidth - scrollLeft, clientWidth)
      visiblePercentage = (visibleWidth / scrollWidth) * 100
      visiblePixels = visibleWidth
      $timelineScrollOffset = scrollLeft
      totalWidth = scrollWidth
    }
  }

  $: container,
    $timelineScale,
    $timelineScaledWidthPx,
    calculateVisiblePercentage()

  // Clean up the event listener when the component is destroyed
  onDestroy(() => {
    container.removeEventListener('scroll', calculateVisiblePercentage)
  })

  $: fetchTimelinesMany($activeProject)
  onMount(() => {
    if (container) {
      // syncHeights()
      const rect = container.getBoundingClientRect()
      timelineContainerWidth = getContainerWidth()
    }
    $timelineScaledWidthPx = timelineContainerWidth
    $timelineWidthPx = timelineContainerWidth
    console.log(
      'timelineScaledWidthPx',
      $timelineScaledWidthPx,
      $timelineWidthPx
    )

    fetchTimelinesMany($activeProject)
    $activeTimeline = $timelines[0]
    scaleTimeline($timelineWidthPx, $timelineScale)
    calculateVisiblePercentage()
    container.addEventListener('scroll', calculateVisiblePercentage)
  })

  function incrementCurrentFrame(diff, value, maxvalue) {
    console.log('incrementCurrentFrame', diff, value, maxvalue)
    let newValue = value + diff
    newValue = Math.min(Math.max(0, newValue), maxvalue)
    return newValue
  }

  function handleKeydown(event, value, maxvalue) {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowRight':
        value = incrementCurrentFrame(1, value, maxvalue)
        return value
      case 'ArrowDown':
      case 'ArrowLeft':
        value = incrementCurrentFrame(-1, value, maxvalue)
        return value
      default:
        return Math.min(Math.max(0, event.target.value), maxvalue)
    }
  }

  function handleWheel(event, value, maxvalue) {
    if (event.deltaY < 0) {
      value = incrementCurrentFrame(1, value, maxvalue)
    } else if (event.deltaY > 0) {
      value = incrementCurrentFrame(-1, value, maxvalue)
    }
    return value
  }

  $: $timelineMaxFrames, scaleTimeline($timelineWidthPx, $timelineScale)

  let timelineContainer

  let rangeMinFr
  let rangeMaxFr

  function setRangeFr() {
    rangeMinFr = parseInt(($range_min * $timelineMaxFrames) / 100, 10)
    rangeMaxFr = parseInt(($range_max * $timelineMaxFrames) / 100, 10)
  }

  function handleZoom(diff) {
    let newValue = $timelineScale + diff
    newValue = Math.min(Math.max(0, newValue), 100)
    $timelineScale = newValue
  }

  function handleRangeFrInput() {
    rangeMinFr = Math.max(0,Math.min(rangeMaxFr-1, rangeMinFr))
    rangeMaxFr = Math.min($timelineMaxFrames, Math.max(rangeMaxFr, rangeMinFr+1))
    let new_range_min = (rangeMinFr / $timelineMaxFrames) * 100
    let new_range_max = (rangeMaxFr / $timelineMaxFrames) * 100
    $range_min = Math.min($range_max, new_range_min)
    $range_max = Math.max($range_min, new_range_max)
    // rangeMinFr = parseInt(($range_min * $timelineMaxFrames) / 100, 10)
    // rangeMaxFr = parseInt(($range_max * $timelineMaxFrames) / 100, 10)
  }

  $: $range_min, $range_max, setRangeFr()
  $: rangeMinFr, rangeMaxFr, handleRangeFrInput()
</script>

<!-- timeline. contains layers (slots for clips)
  for now 1 timeline per project created automatically
 -->
<main
  bind:this={container}
  class="mago-timeline"
  style="height: calc(100vh - 60px);  overflow-x: auto; overflow-y: hidden; width: 40vw; padding-left: 10px;"
>
  <div class="timeline-header" style="position: sticky; left: 0; top:0;">
    <div class="range-container">
      <p class="header-section-title">Frame</p>

      <input
        style="display: inline-block; margin-top: 8px;"
        type="text"
        class="range-value"
        bind:value={$currentFrame}
        on:keydown={(event) =>
          $currentFrame = handleKeydown(event, $currentFrame, $timelineMaxFrames)}
        on:wheel={(event) =>
          $currentFrame = handleWheel(event, $currentFrame, $timelineMaxFrames)}
      />

      <p
        style="margin-top: 16px; margin-left: 144px; display: inline-block; position: absolute; z-index: 10; left: 0; color: var(--text-tertiary)"
      >
        fr.
      </p>

      <p class="header-section-title">Range</p>
      <input
        style="display: inline-block; margin-top: 8px;"
        type="text"
        class="range-value"
        bind:value={rangeMinFr}
        on:keydown={(event) =>
          rangeMinFr = handleKeydown(event, rangeMinFr, $timelineMaxFrames)}
        on:wheel={(event) =>
          rangeMinFr = handleWheel(event, rangeMinFr, $timelineMaxFrames)}
      />
      <p
        style="margin-top: 16px; margin-left: 297px; display: inline-block; position: absolute; z-index: 10; left: 0; color: var(--text-tertiary)"
      >
        fr.
      </p>
      <p class="header-section-title">Range</p>
      <input
        style="display: inline-block; margin-top: 8px;"
        type="text"
        class="range-value"
        bind:value={rangeMaxFr}
        on:keydown={(event) =>
          rangeMaxFr = handleKeydown(event, rangeMaxFr, $timelineMaxFrames)}
        on:wheel={(event) =>
          rangeMaxFr = handleWheel(event, rangeMaxFr, $timelineMaxFrames)}
      />
      <p
        style="margin-top: 16px; margin-left: 450px; display: inline-block; position: absolute; z-index: 10; left: 0; color: var(--text-tertiary)"
      >
        fr.
      </p>
      <!-- <div class="range-value">
        {parseInt(($range_min * $timelineMaxFrames) / 100, 10)}
        <p>fr.</p>
      </div>
      <div class="range-value">
        {parseInt(($range_max * $timelineMaxFrames) / 100, 10)}
        <p>fr.</p>
      </div> -->
    </div>
    <div class="scale-container">
      <p
        class="header-section-title"
        on:click={() => handleZoom(-10)}
        style="cursor: pointer;"
      >
        <ZoomIn />
      </p>
      <input
        type="range"
        min="0"
        max="100"
        bind:value={$timelineScale}
        on:change={$timelineScale}
      />
      <p
        class="header-section-title"
        on:click={() => handleZoom(10)}
        style="cursor: pointer;"
      >
        <ZoomOut />
      </p>
    </div>
  </div>

  <div
    id="timelineList"
    bind:this={timelineContainer}
    style="height: calc(100vh - 130px); overflow: auto; width: 100%;"
  >
    <div
      class="horiz-container"
      style="justify-content: flex-start; padding-left: {controlsWidth}px; position: sticky; top:0; z-index: 25; background-color: var(--surface-primary); width:{$timelineScaledWidthPx +
        5}px;"
    >
      <div
        style="width: {controlsWidth +
          10}px;  position: sticky; left:0; z-index: 25; background-color: var(--surface-primary);"
      ></div>
      <div>
        <RangeSlider width={$timelineScaledWidthPx - controlsWidth} />
        <Ruler
          maxFrames={$timelineMaxFrames}
          width={$timelineScaledWidthPx - controlsWidth}
        />

        <input
          type="range"
          min="0"
          max={$timelineMaxFrames}
          bind:value={$currentFrame}
          on:change={$currentFrame}
          class="video-timeline"
          id="video_timeline"
          style="width: {$timelineScaledWidthPx + 10 - controlsWidth}px;"
        />
      </div>
    </div>
    {#if $activeTimeline}
      <Timeline bind:timeline={$activeTimeline} />
    {/if}
  </div>
</main>

<style>
  .rulers {
    padding-left: 100px;
  }

  .timeline-container {
    height: calc(100vh - 230px);
    padding-left: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
  }
  .timeline-content {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .timeline-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--text-secondary);
    padding: 10px 20px;
    gap: 20px;
    border-bottom: 1px solid black;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
  }

  .range-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;

    & p {
      margin-right: 4px;
    }
  }

  .range-value {
    width: 100px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    padding: 6px;
    background-color: var(--elements-primary);

    & p {
      color: var(--text-tertiary);
    }
  }

  .header-section-title {
    font-size: 13px;
  }

  .video-timeline {
    -webkit-appearance: none;
    appearance: none;
    width: calc(100% + 10px);
    height: 20px;
    background: #363636;
    outline: none;
    cursor: pointer;
    border-radius: 0px;
    border: 1px solid black;
    padding: 0;
    margin: 0;
    position: relative;
    left: -5px;
    right: 10px;
  }

  .scale-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    & > input {
      margin-bottom: 0;
      width: 100%;
      padding: 0;
    }
  }

  /* Style for the slider thumb */
  .video-timeline::-webkit-slider-thumb {
    -webkit-appearance: none; /* Removes default webkit styles */
    appearance: none;
    position: absolute;
    top: 0px; /* Position above the thumb */
    width: 8px; /* Width of the thumb */
    height: 30px; /* Height of the thumb */
    border-radius: 4px;
    background-color: var(--text-action200);
    border: none; /* White color for the thumb */
    position: relative; /* Required for pseudo-element positioning */
  }

  /* Custom house-shaped top for the slider thumb */
  .video-timeline::-webkit-slider-thumb::after {
    content: '';
    position: absolute;
    top: -15px; /* Position above the thumb */
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #fff; /* Same color as the thumb */
  }

  /* Apply similar styles for Firefox */
  .video-timeline::-moz-range-thumb {
    width: 10px;
    height: 30px;
    background-color: #fff;
    border: 1px solid black;
  }

  .video-timeline::-moz-range-thumb::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #fff;
  }
</style>
