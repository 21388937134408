import { get } from 'svelte/store'

import { default_settings as magov1DefaultSettings } from '../data/magov1/data'
import { defaultSettings as comfyDefaultSettings } from '../data/comfyui/data'
import { renderSettingsTypes } from '../data/renderEngine'

import {
  deStringifyOnSave,
  stringifyOnLoad,
  prepareSettings,
  removeNullKeys,
  clearControlnets,
} from '../SettingsUI/utils'
import { settings } from '../stores/renderSettings'
const errorDuration = 10000

const isRenderEmptyMsg = 'These Render Settings are empty: '
const doThisRenderSettingsMsg = 'Please adjust the render settings accordingly.'

const isAppEmptyMsg = 'These Mago App Settings are empty: '
const doThisAppSettingsMsg =
  'Please adjust them by clicking the ⚙️ icon in the topright corner.'

const appSettingsValidationDict = {
  controlnet_dir: 'Controlnet Directory',
  lora_dir: 'Lora Directory',
  embeddings_dir: 'Embeddings Directory',
}

const renderSettingsValidationDict = {
  magov1: {
    model_path: 'Checkpoint path',
  },
  comfyui: {
    model_path: 'Checkpoint path',
    //vae is optional
    controlnet_model_path: 'Controlnet model path',
    animatediff_model_path: 'Animatediff model path',
  },
}

function isInteger(value) {
  return Number.isInteger(value)
}

function isFloat(value) {
  return typeof value === 'number' && !Number.isInteger(value)
}

function isString(value) {
  return typeof value === 'string'
}

function isArray(value) {
  return Array.isArray(value)
}

function isJSON(value) {
  if (typeof value !== 'string') {
    return false
  }
  try {
    JSON.parse(value)
    return true
  } catch (e) {
    return false
  }
}

function validateRenderSettings(settings, settingsTypes) {
  let renderErrors = []
  for (const [key, value] of Object.entries(settings)) {
    let settingType = settingsTypes[key]
    if (value === '' || value === undefined) {
      renderErrors.push(key)
    }
    if (settingType === 'int' && isInteger(value)) {
      continue
    } else if (settingType === 'float' && isFloat(value)) {
      continue
    } else if (
      settingType === 'text' &&
      (isString(value) || isArray(value) || isJSON(value))
    ) {
      continue
    } else {
      renderErrors.push(`${key}: ${value}`)
    }
  }
  return renderErrors
}

export function validateSettings(
  settingsStore,
  appSettingsStore,
  notificationMessageStore
) {
  let engine = get(settingsStore).renderEngine
  let settings = get(settingsStore).renderSettings[engine]
  let settingsTypes = renderSettingsTypes[engine]
  let renderValidationDict = renderSettingsValidationDict[engine]
  let appSettings = get(appSettingsStore)
  let renderSettingsErrors = []
  let appSettingsErrors = []

  if (!get(settingsStore)?.video_init_path) {
    renderSettingsErrors.push('Video Init Path')
  }

  for (const [key, value] of Object.entries(renderValidationDict)) {
    if (!settings[key]) {
      renderSettingsErrors.push(value)
    }
  }

  // renderSettingsErrors = [
  //   ...renderSettingsErrors,
  //   ...validateRenderSettings(settings, settingsTypes),
  // ]

  for (const [key, value] of Object.entries(appSettingsValidationDict)) {
    if (!appSettings.folder_paths[key]) {
      appSettingsErrors.push(value)
    }
  }

  let errorTitle = 'Error queueing up your render.'
  let errorText = []
  if (renderSettingsErrors.length > 0) {
    errorText.push(
      isRenderEmptyMsg,
      renderSettingsErrors.join(', '),
      doThisRenderSettingsMsg
    )
  }
  if (appSettingsErrors.length > 0) {
    errorText.push(
      isAppEmptyMsg,
      appSettingsErrors.join(', '),
      doThisAppSettingsMsg
    )
  }

  console.log('validating settings', renderSettingsErrors, appSettingsErrors)
  if (renderSettingsErrors.length + appSettingsErrors.length > 0) {
    notificationMessageStore.set({
      title: errorTitle,
      text: errorText.join('\n'),
      type: 'error',
      duration: errorDuration,
    })
    return false
  }
  return true
}

//make it a function in case we need to preprocess data to display in js (like stringify objects)
export function getDefaultRenderSettings() {
  return {
    renderSettings: {
      magov1: magov1DefaultSettings,
      comfyui: comfyDefaultSettings,
    },
    renderEngine: 'magov1',
  }
}

export function onLoadSettings(settings) {
  settings.renderSettings = Object.fromEntries(
    Object.entries(settings.renderSettings).map(([key, value]) => [
      key,
      stringifyOnLoad(prepareSettings(value)),
    ])
  )
  return settings
}

export function getInitSettings() {
  let result = onLoadSettings(getDefaultRenderSettings())
  console.warn('getInitSettings()', result)
  return result
}

export function getSettings() {
  return get(settings)
}

export function setSettings(newSettings) {
  settings.set(newSettings)
  return settings
}

export function prepareSettingsForSaving(settings) {
  settings.renderSettings = Object.fromEntries(
    Object.entries(settings.renderSettings).map(([key, value]) => [
      key,
      deStringifyOnSave(removeNullKeys(value)),
    ])
  )
  console.warn('prepareSettingsForSaving()', settings)
  return settings
}

export function clearControlnetsDict(settings) {
  settings.renderSettings = Object.fromEntries(
    Object.entries(settings.renderSettings).map(([key, value]) => [
      key,
      clearControlnets(value),
    ])
  )
  return settings
}
