import { writable } from 'svelte/store'
import { all_possible_controlnets } from './data/magov1/data'
import {
  getInitSettings,
  onLoadSettings,
} from './controllers/settingsController'

// Default values
let backendUrlBase = 'http://localhost:6001'
let apiBaseUrl = window.location.origin 
if (!apiBaseUrl.includes('localhost') && !apiBaseUrl.includes('127.0.0.1')) {
  // Adjust apiBaseUrl for production or ngrok usage
  backendUrlBase = apiBaseUrl
  
}
export let backendUrl = backendUrlBase
console.log('backendUrl', backendUrl)
 
export let settings = writable(onLoadSettings(getInitSettings()))
export let selectedProject = writable(null)
export let currentFrame = writable(0)
export let duration = writable(0) // actual video duration
export let max_duration = writable(300) // user selected timeline duration (scale)
export const cacheBuster = writable(Date.now()) // Generate a unique cache buster value
export const range_min = writable(0)
export const range_max = writable(10)
// export const timeline_maxsize = writable(200);
export const TIMELINE_MINSIZE = 40
export const popup_message = writable('')
export const queue_length = writable(0)
export const userId = writable('')
export const settingsOpen = writable(false)
export const sliderValue = writable(50)
export const queue = writable([])
export const openModalId = writable(null);
